import React from 'react';
import { Modal, Button, TextInput, Group, Table, Paper ,ScrollArea } from '@mantine/core';

function EmployeeModal({
  opened,
  setOpened,
  employees,
  formData,
  setFormData,
  handleSubmit,
  handleEdit,
  handleDelete,
  resetForm
}) {
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        resetForm();
        setOpened(false);
      }}
      title="Manage Employees"
      size="xxl"
    >
      <Group direction="column" spacing="md">
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Group grow>
            <TextInput
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleFormChange}
              required
            />
            <TextInput
              label="Basic Salary"
              name="basic_salary"
              type="number"
              value={formData.basic_salary}
              onChange={handleFormChange}
              required
            />
            <TextInput
              label="Work Days"
              name="work_days"
              type="number"
              value={formData.work_days}
              onChange={handleFormChange}
              required
            />
            <TextInput
              label="Mobile Number"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={handleFormChange}
              required
            />
            <TextInput
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleFormChange}
            />
          </Group>
          <Button type="submit" style={{ marginTop: 10 }}>{formData.id ? 'Update' : 'Add'} Employee</Button>
        </form>
        <Paper style={{ width: '100%', overflowX: 'auto' }}>
          <Table striped highlightOnHover withTableBorder withColumnBorders component={ScrollArea} >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Basic Salary</Table.Th>
                <Table.Th>Work Days</Table.Th>
                <Table.Th>Actions</Table.Th>
                <Table.Th>Mobile Number</Table.Th>
                <Table.Th>Email</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {employees.map((employee) => (
                <Table.Tr key={employee.id}>
                  <Table.Td>{employee.name}</Table.Td>
                  <Table.Td>{employee.basic_salary}</Table.Td>
                  <Table.Td>{employee.work_days}</Table.Td>
                  <Table.Td>{employee.mobile_number}</Table.Td>
                  <Table.Td>{employee.email}</Table.Td>
                  <Table.Td>
                    <Button
                      style={{ marginRight: 10 }}
                     onClick={() => handleEdit(employee)}>Edit</Button>
                    <Button color="red" onClick={() => handleDelete(employee.id)}>Delete</Button>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Paper>
      </Group>
    </Modal>
  );
}

export default EmployeeModal;
